
document.addEventListener('DOMContentLoaded',function() {
    document.querySelector('#properties-quick-search-form').addEventListener('submit', function(event) {
        event.preventDefault();
        var search = event.target.querySelector('#search-term').value;

        if (search) {
            search = '?search=' + search;
        }
        window.location.href = '/proprietes' + search;
    });

});